import {BrowserRouter, Navigate, Route, RouteProps, Routes} from "react-router-dom";
import {Catalog, 
        ChangePassword, 
        Home, 
        Login, 
        LoginAuto,
        MenuAgent, 
        ProfileOptions,
        New,
        LoginFirma17, 
        ReferenciasYDocumentos,
        InformacionFinanciera10,
        SolicitudesDeCredito,
        DetalleDelCredito,
        DetalleDelCodeudorRequerido,
        MensajeDespuesdeValidarSargilat,
        ConfirmInformation,
        CodebtInfo
    } from "./views";
import {LoginFirma} from "./views/LoginFirma/LoginFirma";
import {PDFView} from "./components/Sign/DocumentView";
import { Confirmation } from "./views/Confirmation/Confirmation";
import * as _ from 'lodash';
import { useLoginContext } from "./hooks/useLoginContext";
import { ProtectedRoute } from "./components/Authorization/ProtectedRoute";
import { PDFViewSign } from "./components/Sign/DocumentViewSign";
import { ProtectedRouteSign } from "./components/Authorization/ProtectedRouteSign";
import { useSignContext } from "./hooks/useSignContext";
import { NewPdf } from "./views/New/NewPdf";

export enum AppRoutes {
    LOGIN = '/login',
    LOGINAUTO = '/loginauto',
    PROFILE = '/profile',
    PROFILE_OPTIONS = '/profile-options',
    CATALOG = '/catalog',
    HOME = '/home',
    MENU_USER = '/menu-agent',
    CHANGE = '/change-password',
    DOCUMENTS = '/documents',
    SIGN = '/sign',
    VIEW_DOCUMENT = '/view_document',
    SARGILAT = '/sargilat',
    PROPETARIO='/CoDebt-information',
    VIEW_DOCUMENT_SIGN = '/view_document_sign',
    CONFIRMATION = '/confirmation',
    // New
    NEW = '/new',
    LOGINFIRMA17 = '/loginfirma17',
    REFERENCIAS_Y_DOCUMENTOS_13 = '/Referencias-y-documentos-13',
    INFORMACION_FINANCIERA_10 = '/Informacion-financiera-10',
    SOLICITUDES_DE_CREDITO = '/Solicitudes-de-credito',
    DETALLE_DEL_CREDITO = '/Detalle-del-credito',
    DETALLE_DEL_CODEUDOR_REQUERIDO = '/Detalle-del-codeudor-requerido',
    MENSAJE_DESPUES_DE_VALIDAR_SARGILAT = '/Mensaje-despues-de-validar-sargilat',
}


export function Router() {
    const { user } = useLoginContext();
    const { sign } = useSignContext();

    return (
        <BrowserRouter>    
            <Routes>
                <Route index element={<ProfileOptions />} />
                <Route path="profile-options" element={<ProfileOptions />} />
                <Route path="catalog" element={ <Catalog/>} />
                <Route element={<ProtectedRoute user={user} />}>
                    <Route path="home" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="loginauto" element={<LoginAuto />} />
                    <Route path="menu-agent" element={<MenuAgent/>} />
                    <Route path="CoDebt-information" element={<CodebtInfo/>} />
                    <Route path="Detalle-del-codeudor-requerido" element={<DetalleDelCodeudorRequerido/>} />
                    <Route path="Solicitudes-de-credito" element={<SolicitudesDeCredito/>} />
                    <Route path="Detalle-del-credito" element={<DetalleDelCredito/>} />
                </Route>
                <Route path="sign" element={<LoginFirma17 />} />
                <Route element={<ProtectedRouteSign sign={sign} />}>
                    <Route path="view_document" element={<PDFView/>} />
                    <Route path="view_document_sign" element={<PDFViewSign/>} />
                     <Route path="sargilat" element={<MensajeDespuesdeValidarSargilat/>} />
                </Route>
                <Route path="confirmation" element={<Confirmation />} />
                <Route path="*" element={<p>There's nothing here: 404!</p>} />

                {/* Sprint1 Pages Without ProtectRouted */}
                <Route path="home_view" element={<Home />} />
                <Route path="login_view" element={<Login />} />
                <Route path="menu_agent_view" element={<MenuAgent/>} />
                <Route path="view_document_view" element={<PDFView/>} />
                <Route path="view_document_sign_view" element={<PDFViewSign/>} />
                <Route path="confirmation_view" element={<Confirmation />} />
                <Route path="change-password" element={<ChangePassword />} />

                {/* Sprint 2 New */}
                <Route path="new" element={<New/>} />
                <Route path="loginfirma17" element={<LoginFirma17/>} />
                <Route path="Referencias-y-documentos-13" element={<ReferenciasYDocumentos/>} />
                <Route path="Informacion-financiera-10" element={<InformacionFinanciera10/>} />
                <Route path="Confirm-information" element={<ConfirmInformation/>} />
                <Route path="new-pdf" element={<NewPdf/>} />

            </Routes>
      </BrowserRouter>
    );
}
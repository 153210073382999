import React, { Dispatch, SetStateAction,useEffect } from 'react';
import './rd.scss';
import {Document, Check} from '../../../assets/icons/component';
import { ModalReferences } from '../../Steps/DocumentsInformation/ModalReferences/ModalReferences';
import { useLoginContext } from '../../../hooks/useLoginContext';
import { useRequestContext } from '../../../hooks/useRequestContext';
import toast from 'react-hot-toast';
import ClockLoader from "react-spinners/ClockLoader";
import {useState} from 'react';
import {Parentescos} from "../../../__mocks__/data";
import { WloCreditoService } from '../../../types';
import {AppRoutes} from "../../../Router";
import { WLOCreditAPIService } from '../../../services';
import { Navigate, useNavigate } from 'react-router-dom';
import { DetailCreditRequire } from "../../shared/DetailCreditRequire/DetailCreditRequire";

interface Listo {
  info: {
    item1:string,
    item2:string,
    item3:string,
    item4:string,
    item5:string
  };
};
interface Listo4 {
  info: {
    item1:string,
    item2:string,
    item3:string,
    item4:string
  },
  arreglo:any
};
const Data = [
  {
    item1: 'Familiar',
    item2: 'Parentesco',
    item3: 'Nombre completo',
    item4: '4325678',
    item5: '3143566778'
  },
  {
    item1: 'Familiar',
    item2: 'Parentesco',
    item3: 'Nombre completo',
    item4: '4325678',
    item5: '3143566778'
  },
  {
    item1: 'Familiar',
    item2: 'Parentesco',
    item3: 'Nombre completo',
    item4: '4325678',
    item5: '3143566778'
  },
  {
    item1: 'Personal',
    item2: 'Parentesco',
    item3: 'Nombre completo',
    item4: '4325678',
    item5: '3143566778'
  },
  {
    item1: 'Personal',
    item2: 'Parentesco',
    item3: 'Nombre completo',
    item4: '4325678',
    item5: '3143566778'
  }
]
const Data4 = [
  {
    item1: 'Nombre completo',
    item2: '3143566778',
    item3: 'ejemplo@gmail.com',
    item4: 'Aceptado'
  },
  {
    item1: 'Nombre completo',
    item2: '3143566778',
    item3: 'ejemplo@gmail.com',
    item4: 'Aceptado'
  },
  {
    item1: 'Nombre completo',
    item2: '3143566778',
    item3: 'ejemplo@gmail.com',
    item4: 'Aceptado'
  }
]

const ListItem: React.FC <any> = ({info}) => {
  if(info){

    let ref=(info.i_ref_tip==1?'FAMILIAR':'PERSONAL')
    let parent=info.n_pws_vinculo
    let nomcom=(info.c_pws_ref_nomcomple)
    let telf=(info.i_pws_ref_telfu)
    let telmo=(info.i_pws_ref_telcle)


  return(
    <ul>
    <li className="listItem">{ref}</li>
      <li className="listItem">{parent}</li>
      <li className="listItem">{nomcom}</li>
      <li className="listItem">{telf}</li>
      <li className="listItem">{telmo}</li>
    </ul>
  )
}else{
  return(<></>)
}
}
const ListItem4: React.FC <Listo4> = (info:any,arreglo:any) => {
  const navigate = useNavigate();

  let nomrbes="";
  let telefono="";
  let correo="";
  let estado="";
  if(info.info.data){
    nomrbes=info.info.data.Pws_Nombres+" "+ info.info.data.Pws_Apellidos1+" "+ info.info.data.Pws_Apellidos2
    telefono=info.info.data.Pws_Telefono1;    
    correo=info.info.data.Pws_Correo
    if(Number(info.info.codeudor.Pws_Estado)==1)estado="Simulador"
    if(Number(info.info.codeudor.Pws_Estado)==2)estado="Confirmación información"
    if(Number(info.info.codeudor.Pws_Estado)==3)estado="Firma documentos"
    if(Number(info.info.codeudor.Pws_Estado)==4)estado="Aprobado"
    if(Number(info.info.codeudor.Pws_Estado)==5)estado="Rechazado"
    if(Number(info.info.codeudor.Pws_Estado)==6)estado="Eliminado"
  }
  const irA =()=>{
    if(Number(info.info.codeudor.Pws_Tip_Codeu)==1){
      navigate(AppRoutes.PROPETARIO+"?"+info.arreglo[0]+"-"+info.arreglo[1]+"-"+info.arreglo[2]+"-"+info.info.codeudor.Pws_codigo)

    }else if(Number(info.info.codeudor.Pws_Tip_Codeu)==2){
      navigate(AppRoutes.DETALLE_DEL_CODEUDOR_REQUERIDO+"?"+info.arreglo[0]+"-"+info.arreglo[1]+"-"+info.arreglo[2]+"-"+info.info.codeudor.Pws_codigo)

    }
  }
  return(
    <ul>
      <li className="listItem">{nomrbes}</li>
      <li className="listItem">{telefono}</li>
      <li className="listItem">{correo}</li>
      <li className="listItem">{estado}</li>
      <li className="listItem"><button onClick={()=>{
        
        irA()
        }}>Ver</button></li>
    </ul>
  )
}
interface refer {
  onfunc: Dispatch<SetStateAction<boolean>>;
  funcon:()=>void;
  listadeitem:Array<any>;
}
export const ReferenceTable2 = (props:refer) => {
  const {onfunc,funcon,listadeitem} = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
        setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return(
    <div className="referenceTable">
      <ClockLoader id='spinner' color="#89AF51" loading={isLoading} size={100}/>
      <div className="title">Referencias</div>
      <div className="table">
        <div className="responsiveFix">
          <div className="responsiveAuto">
            <ul>
              <li className="listItem listHeader">Tipo de referencia</li>
              <li className="listItem listHeader">Parentesco</li>
              <li className="listItem listHeader">Nombres completo</li>
              <li className="listItem listHeader">{`Teléfono fijo (Si tiene)`}</li>
              <li className="listItem listHeader">Teléfono movil</li>
            </ul>
            {listadeitem?<>{listadeitem.length>0?listadeitem.map((valor,index)=>{
                
                return(
                  <ListItem key={index} info={valor}/>
                )
              
              }):<></>}</>:<></>
            }
          </div>
        </div>
        <div className="agregar">
          <button type='button' onClick={funcon}>Agregar</button>
        </div>
      </div>
    </div>
  )
}
export const ReferenceTable = (references:any) => {
  const [referencias, valorRefere] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [valuess, setValues] = React.useState({});
  const toggle = () => setModalShow(!modalShow);
  useEffect(()=>{
      valorRefere(references.references.refer)
      setValues(references.references.values)
      },[references]);
  return(
    <div className="referenceTable">
      <div className="title">Referencias</div>
      <div className="table">
        <div className="responsiveFix">
          <div className="responsiveAuto">
            <ul>
              <li className="listItem listHeader">Tipo de referencia</li>
              <li className="listItem listHeader">Parentesco</li>
              <li className="listItem listHeader">Nombre completo</li>
              <li className="listItem listHeader">{`Teléfono fijo (Si tiene)`}</li>
              <li className="listItem listHeader">Teléfono movil</li>
            </ul>
            {
              referencias.map((info,i)=>{
                return(
                  <ListItem key={i} info={info}/>
                )
              })
            }
          </div>
        </div>
        <div className="agregar">
          <button type='button' onClick={()=>{setModalShow(true)}}>Agregar</button>
        </div>
      </div>
      <ModalReferences
                    values={valuess}
                    show={modalShow}
                    valorrequest={true}
                    key={1}
                    onHide={() => {
                     // references.references.Busquedatotals()
                      setModalShow(false)}}
                />
    </div>
    
  )
}
export const CodeudorTable = (propetarios:any) => {
  const navigate = useNavigate();
  const {request, setRequest} = useRequestContext();
  const [propetario,agregapro]=React.useState([] as any) 
  useEffect(()=>{
    if(propetarios.propetarios.valor){
      agregapro(propetarios.propetarios.valor)

    }
  },[propetarios])
  const irpara=()=>{
    
    setRequest({
      ...request,
      dataModifcable: {}
  })
    if(propetarios.propetarios.type=="prop"){
      navigate(AppRoutes.PROPETARIO+"?"+propetarios.propetarios.arreglo[0]+"-"+propetarios.propetarios.arreglo[1]+"-"+propetarios.propetarios.arreglo[2])

    }else if(propetarios.propetarios.type=="req"){
      navigate(AppRoutes.DETALLE_DEL_CODEUDOR_REQUERIDO+"?"+propetarios.propetarios.arreglo[0]+"-"+propetarios.propetarios.arreglo[1]+"-"+propetarios.propetarios.arreglo[2])

    }
  }
  return(
    <div className="referenceTable">
      <div className="table">
        <div className="responsiveFix">
          <div className="responsiveAuto">
            <ul>
              <li className="listItem listHeader">Nombre completo </li>
              <li className="listItem listHeader">Teléfono movil</li>
              <li className="listItem listHeader">Correo</li>
              <li className="listItem listHeader">Estado</li>
              <li className="listItem listHeader"><button onClick={()=>{irpara()}}>Ingresar</button></li>
            </ul>
            {
              propetario.map((info:any,i:any)=>{
                return(
                  <ListItem4 key={i} info={info} arreglo={propetarios.propetarios.arreglo}/>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

interface Adjuntarprop {
  req: any;
  val: any;
}
export const AdjurDoc = (props:Adjuntarprop) => {
  const [option1, setoption1] = React.useState(false);
  const [option2, setoption2] = React.useState(false);
  const [option3, setoption3] = React.useState(false);
  const {val,req} = props;
  const handleFileInputChange = (e: any) => {
    const target = e.target
    if (target.files && target.files[0]) {
        const maxAllowedSize = 5 * 1024 * 1024;
        if (target.files[0].size > maxAllowedSize) {
            toast.error('Seleccione un archivo de tamaño máximo 5mb.',{
              duration:5000,
          });
            target.value = ''
        }
    }
    let file: any = '';
    file = e.target.files[0];
    getBase64(file)
        .then(async result => {
            const document = result as string;
            const documentRequest= {
                Pws_num_solicitud: req.numSol!,
                Pws_Identificacion: val.Pws_Identificacion,
                Pws_Tip_Identificacion: val.Pws_Tip_Identificacion,
                Pws_Doc_Tipo: target.dataset.id,
                Pws_Doc_nombre: file.name,
                Pws_Doc_estado: "1",
                Pws_Doc_ruta: "1",
                Pws_Clave_doc: document,
            };
           
            (async () => {
              const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
              if(Number(solicitud.payload.result)>0){
                const dataElement = target.closest("[data-id]");
                if(Number(dataElement.dataset.id)===1){
                  setoption1(true)
                }
                if(Number(dataElement.dataset.id)===2){
                  setoption2(true)
                }
                if(Number(dataElement.dataset.id)===3){
                  setoption3(true)
                }
              }
             
            })();


        })
        .catch((err) => {
            console.log(err);
        });
};

const getBase64 = (file: any) => {
  return new Promise(resolve => {
      let baseURL: string | ArrayBuffer | null = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          baseURL = reader.result;
          resolve(baseURL);
      };
  });
};
  return(
    <div className="footer">
      <div className="footer-text">
        <h3>Adjuntar documentos</h3>
      </div>
      <div className="referenceTable">
        <p><span>{`Documetación requerida`}</span>{`(Los documentos se permiten PDF, JPG Y PNG. Tamaño máximo de 5Mb) `}</p>
      <div className="footer-body">
        <div className={option1?"document check":"document"} onClick={(eve) => {
eve.currentTarget.querySelector('input')?.click()
}}>
        <input
        
        data-id="1"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        onChange={handleFileInputChange}
                    />
                    {option1? <><Check/></>:<><Document/></>}
            <p>Copia de cedula</p>
        </div>
        <div id='mid' className={option2?"document check":"document"} onClick={(eve) => {
 eve.currentTarget.querySelector('input')?.click()
}}>
        <input
        data-id="2"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        onChange={handleFileInputChange}
                    />
            {option2? <><Check/></>:<><Document/></>}
            
            <p>
              Certificado bancario
              <br />
              <span className="paragraph-color-blue"> (Este documento es requerido para el desembolso de su crédito)</span>
            </p>
        </div>
        <div id='right' className={option3?"document check":"document"} onClick={(eve) => {
  eve.currentTarget.querySelector('input')?.click()
}}> 
        <input
        data-id="3"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"
                        onChange={handleFileInputChange}
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                    />
                    {option3? <><Check/></>:<><Document/></>}
            <p>Soportes de ingresos y/o garantías</p>
        </div>
      </div>
    </div>
    </div>

  )
}
interface DocumentsInformationProps {
  errors?: any;
  values?: any;
}
export const ReferenceDocument  = (props: DocumentsInformationProps)  => {
  const [modalShow, setModalShow] = React.useState(false);
  const toggle = () => setModalShow(!modalShow);
  const [popup, setPopup] = useState("close");
  const {values} = props;
  const {user} = useLoginContext();
  const {request, setRequest} = useRequestContext();
  
  const shouldRenderDetailCreditRequire = values.pws_cod_credi === "547";
  return (
    <div className="Referencias">
      <div className="header">
        <h3>Referencias y documentos</h3>
        <p>Por favor diligencia los datos </p>
      </div>
      <ReferenceTable2
      onfunc={setModalShow}
      funcon={toggle}
      listadeitem={request.references}
      />
      <AdjurDoc
      val={values}
      req={request}
      />
      {(shouldRenderDetailCreditRequire && (<DetailCreditRequire  setPopup={setPopup} /> )) || null}

      <ModalReferences
                    values={values}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
    </div>

  )
}

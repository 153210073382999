import {Form, Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import { Row} from 'reactstrap';
import {AppRoutes} from '../../Router';
import './login-firm-17.scss';
import * as Yup from "yup";
import {Fab} from '@mui/material';
import {typeIdentification} from '../../__mocks__/data';
import {MenuBar} from '../../components/shared/Menubar/Menubar';
import './login-firm-17.scss';
import { useIntl } from 'react-intl';
import { useSignContext } from '../../hooks/useSignContext';
import { AuthService } from '../../services/AuthService';
import toast from 'react-hot-toast';
import ClockLoader from 'react-spinners/ClockLoader';
import { WLOCreditAPIService } from '../../services/WLOCreditService';
import { useRequestContext } from '../../hooks/useRequestContext';
import {useLoginContext} from '../../hooks/useLoginContext';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import {decodeToken} from 'react-jwt';

interface LoginFirmaProps {
}

export function LoginFirma17(props: LoginFirmaProps) {
    let [isLoading, setIsLoading] = useState(false);
    const [numeroSolicitud, setNumeroSolicitud] = useState(0);
    const [valoreslogins, setvalores] = useState({}as any);
    const {user, setUser} = useLoginContext();
    const intl = useIntl();
    const {request, setRequest} = useRequestContext();
    const {sign, setSign} = useSignContext();
    const navigate = useNavigate();
    const buscar = useLocation();

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const validation = await AuthService.loginValidacion({
                Pws_Identificacion: values.Pws_Identificacion,
                Pws_Tip_identificacion: values.Pws_Tip_Identificacion,
                Pws_Correo:values.Pws_Correo,
                Pws_Telefono:values.Pws_Telefono
            });

            if (Number(validation.payload.result) > 0) {
                if(numeroSolicitud>0){
                    const solicitudResulado = await WLOCreditAPIService.addSolicitud({
                        Pws_num_solicitud:String(numeroSolicitud),
                        Pws_Identificacion: values.Pws_Identificacion,
                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion
                    });
                    if(Number(solicitudResulado.payload.result)>0){
                        if(solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado<5){
                          
                                if(Number(valoreslogins.tipodeudor)<2){
                                    solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="2"
                                    const solicitudResulados = await WLOCreditAPIService.addSolicitud(solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0])

                                }
                                 setRequest({
                                ...request,
                                numSol: String(numeroSolicitud),
                                personCygnus:solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0],
                                valoresLogin:valoreslogins
                            });
                            setUser({
                                ...user,
                                idUser: values.Pws_Identificacion,
                                lastLoginDate: new Date().toDateString(),
                                isSuccessfulLogin: true
                            });
                                setSign({...sign, numIdentification: values.Pws_Identificacion,
                                typeIdentification: values.Pws_Tip_Identificacion,
                                email: values.Pws_Correo,
                                phoneNumber: values.Pws_Telefono,
                                identityValidated: true
                            })
                            setIsLoading(false);
                            navigate(AppRoutes.VIEW_DOCUMENT, {replace: true});
                        }else{ 
                            setIsLoading(false);
                            toast.error('La Solicitud Fue Negada',{
                                duration:5000,
                            });

                        }
                    }else{
                        setIsLoading(false);
                        toast.error('No es el Numero de Solicitud de la persona',{
                            duration:5000,
                        });

                    }
                }else{
                    setIsLoading(false);
                    toast.error('No Cuentas con el Token',{
                        duration:5000,
                    });

                }
                    
                
            } else {
                setSign({...sign, numIdentification: values.Pws_Identificacion,
                    typeIdentification: values.Pws_Tip_Identificacion,
                    email: values.Pws_Correo,
                    phoneNumber: values.Pws_Telefono,
                    identityValidated: false
                })
                setIsLoading(false);
                toast.error(intl.formatMessage({id: "toast_failed_identity_validation"},{
                    duration:5000,
                }));
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('En este momento no podemos procesar su solicitud',{
                duration:5000,
            });
        }
    };
    useEffect(()=>{
        if(buscar){
            var valorBusqueda=buscar.search.replace("?","")
            const valorde:any=decodeToken(valorBusqueda)
            if(valorde){
                if(Number(valorde.solicitudnum)>0){
                    setNumeroSolicitud(Number(valorde.solicitudnum))
                    setvalores(valorde)
                }else{
                    toast.error("No tienes La Solicitud",{
                        duration:5000,
                    })
                            }
            }else{
                toast.error("No tienes La Solicitud",{
                    duration:5000,
                })
            }
        
        }
        },[buscar,navigate]);
    useEffect(() => {
        console.log(sign)
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);
    const initialValues = {
        Pws_Tip_Identificacion: '',
        Pws_Identificacion: '',
        Pws_Correo: '',
        Pws_Telefono: ''
    };

    const validationSchema = Yup.object().shape({
        Pws_Tip_Identificacion: Yup.string()
            .required('( * )'),
        Pws_Identificacion: Yup.string().required('( * )'),
        Pws_Correo: Yup.string()
            .required('( * )'),
        Pws_Telefono: Yup.string()
            .required('( * )'),
    })

    return (
        <div className="loginfirma17">
            <MenuBar/>
            <ClockLoader id='spinner' color={"#89AF51"} loading={isLoading} size={100}/>
            <div className='loginfirm'>
                <div className='img-logo'/>
                <div className='title-login'>{'Antes que todo validemos tu identidad'}</div>
                <div className='subtitle-login'>{'Ingresa los siguientes datos para continuar'}</div>
                <div className='login-container'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <>
                                <Form>
                                    <Row>
                                        <section>
                                            <Field as="select"
                                                disabled={isLoading}
                                                className='form-control-50-credit'
                                                name="Pws_Tip_Identificacion">
                                                <option>{'Tipo de documento'}</option>
                                                {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item,i) => {
                                                    return (
                                                        <option key={i} value={item.s_codigo}>{item.s_descripcion}</option>
                                                    )
                                                })}
                                            </Field>
                                        </section>
                                        <section>
                                            <div className='form-control-login-text'>{'Documento'}</div>
                                            <Field
                                                className='form-control-50-credit'
                                                id="Pws_Identificacion"
                                                name="Pws_Identificacion"
                                                type="text"
                                                disabled={isLoading}
                                                placeholder='Número de documento'
                                            />
                                            <br/>
                                        </section>
                                        <section>
                                            <h4 className="title-firma">{'Inserta una dirección de correo electrónico y tu número de celular para  enviarte el código de validación.'}</h4>
                                            <Field
                                                className='form-control-50-credit'
                                                id="Pws_Correo"
                                                name="Pws_Correo"
                                                type="email"
                                                disabled={isLoading}
                                                placeholder='Correo electrónico'
                                            />
                                            <br/>
                                            <Field
                                                className='form-control-50-credit'
                                                id="Pws_Telefono"
                                                name="Pws_Telefono"
                                                type="text"
                                                disabled={isLoading}
                                                placeholder='Celular'
                                            />
                                            <br/>
                                        </section>
                                        <section>
                                            <Fab className="form-control-small-button" variant="extended" type='submit'
                                                size="medium"
                                                color="neutral" aria-label="add">
                                                {'Ingresar'}
                                            </Fab>
                                        </section>
                                    </Row>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import './assets/styles/shared.scss';
import './App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Router} from './Router';
import { IntlProvider } from 'react-intl';
import { messages } from './assets/translations/messages';
import { Toaster } from 'react-hot-toast';
import Cookies from "js-cookie";
import { MenuBar } from './components';
import { LoginProvider } from './context/LoginContext';
import { RequestProvider } from './context/RequestContext';
import { useLoginContext } from './hooks/useLoginContext';
import { red } from '@mui/material/colors';
import { SignProvider } from './context/SignContext';

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}

// Update the Button's color prop options
declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        neutral: true;
    }
}

function App() {
    const theme = createTheme({
        palette: {
            neutral: {
                main: '#8491a3',
                contrastText: '#fff',
            },
            primary: {
                main: "#89AF51",
              },
        },
    });


    const language = Cookies.get('language') || 'es';

    return (
        <div className="App">
            <LoginProvider>
                <RequestProvider>
                    <SignProvider>
                        <IntlProvider locale={language} messages={messages[language] as any}>
                            <ThemeProvider theme={theme}>
                                <Router/>
                                <Toaster />
                            </ThemeProvider>
                        </IntlProvider> 
                    </SignProvider>
                </RequestProvider>
            </LoginProvider>
        </div>
    );
}

export default App;

import {Field, Form, Formik} from 'formik';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Row} from 'reactstrap';
import {AppRoutes} from '../../Router';
import './Login.scss';
import '../../assets/styles/shared.scss';
import * as Yup from "yup";
import {typeIdentification} from '../../__mocks__/data';
import {WloCreditoService} from '../../types';
import {AuthService} from '../../services';
import toast from 'react-hot-toast';
import {useIntl} from "react-intl";
import ClockLoader from "react-spinners/ClockLoader";
import * as _ from 'lodash';
import {Fab} from '@mui/material';
import {useLoginContext} from '../../hooks/useLoginContext';
import { MenuBar } from '../../components';
import { DirectionButton } from '../../components/shared/Menubar/DirectionButton';

export function Login() {
    const {user, setUser} = useLoginContext();
    const navigate = useNavigate();
    const intl = useIntl();
    let [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            if (_.isEmpty(values.Pws_Identificacion) || _.isEmpty(values.Pws_clave_usu)) {
                toast.error('Los campos Identificación, Tipo de Identificación y Clave son obligatorios.',{
                    duration:5000,
                });
                setTimeout(() => {
                    setIsLoading(false);
                }, 5000);
            } else {
                localStorage.setItem('Gestor',values.Pws_Identificacion)
                const userLogin: WloCreditoService.LoginRequest = {
                    Pws_Identificacion: values.Pws_Identificacion,
                    Pws_Tip_identificacion:"1" ,
                    Pws_clave_usu: values.Pws_clave_usu,
                    Pws_Tip_perfil: user.idProfile!,
                    Pws_Tip_Canal: "1",     // Valor constante que refiere al TIPO_CANAL
                    Pws_Tip_cambio: "1",    // Valor constante que refiere al ACCESO_USUARIO
                }
                const result = await AuthService.login(userLogin);
                if (result.payload.Ws_LoginResult.Resultado <= 0) {
                    setUser({
                        ...user,
                        typeUse:values.Pws_Tip_Identificacion,
                        idUser: values.Pws_Identificacion,
                        lastLoginDate: new Date().toDateString(),
                        isSuccessfulLogin: false
                    });
                    toast.error('Favor valide sus datos',{
                        duration:5000,
                    });
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 5000);
                } else {
                    localStorage.setItem('User', JSON.stringify({
                        ...user,
                        typeUse:values.Pws_Tip_Identificacion,
                        idUser: values.Pws_Identificacion,
                        lastLoginDate: new Date().toDateString(),
                        isSuccessfulLogin: true
                    }))
                    setUser({
                        ...user,
                        typeUse:values.Pws_Tip_Identificacion,
                        idUser: values.Pws_Identificacion,
                        lastLoginDate: new Date().toDateString(),
                        isSuccessfulLogin: true
                    });
                    setIsLoading(false);
                    toast.success('Bienvenido',{duration:5000});
                    navigate(AppRoutes.MENU_USER, {replace: true});
                }
            }
        } catch (e) {
            setIsLoading(true);
            toast.error('En este momento no podemos atender su solicitud',{
                duration:5000,
            });
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }
    };
    useEffect(() => {
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);

    const validationSchema = Yup.object().shape({
        // Pws_Tip_Identificacion: Yup.string()
        //     .required('(*)'),
        Pws_Identificacion: Yup.string().required('(*)'),
        Pws_clave_usu: Yup.string()
            .required('(*)'),
    })

    return (
        <>
            <ClockLoader id='spinner' color={"#89AF51"} loading={isLoading} size={100}/>

            <MenuBar/>
            <DirectionButton to="/" label="" />
            <div className='login-container'>
                <br />
                <div className='title-login'>{intl.formatMessage({id: "login_title"})}</div>
                <div className='subtitle-login'>{intl.formatMessage({id: "login_subtitle"})}</div>
                <br/>
                <br/>
                <Formik
                    initialValues={{
                        // Pws_Tip_Identificacion: '1',
                        Pws_Identificacion: '',
                        Pws_clave_usu: '',
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({errors, isSubmitting}) => (
                        <>
                            <Form>
                                <Row>
                                    <section>
                                        {/* <Field disabled={isLoading}
                                               as="select"
                                               className='form-control-50-login'
                                               name="Pws_Tip_Identificacion">
                                            <option>{errors.Pws_Tip_Identificacion ? 'Documento' : intl.formatMessage({id: "placeholder_document_type"})}</option>
                                            {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                                                return (
                                                    <option key={key}
                                                            value={item.s_codigo}>{item.s_descripcion}</option>
                                                )
                                            })}
                                        </Field> */}
                                        <div
                                            className='form-control-login-text'>{intl.formatMessage({id: "form_login_document"})}</div>
                                        <br/>
                                    </section>
                                    <section>
                                        <Field
                                            disabled={isLoading}
                                            className='form-control-50-login'
                                            id="Pws_Identificacion"
                                            name="Pws_Identificacion"
                                            type="text"
                                            placeholder={errors.Pws_Identificacion ? 'Es obligatorio' : intl.formatMessage({id: "placeholder_document_number"})}
                                        />
                                        <div
                                            className='form-control-login-text'>{intl.formatMessage({id: "form_login_password"})}</div>
                                        <br/>
                                    </section>
                                    <section>
                                        <Field
                                            disabled={isLoading}
                                            className='form-control-50-login'
                                            id="Pws_clave_usu"
                                            name="Pws_clave_usu"
                                            type="password"
                                            placeholder={errors.Pws_clave_usu ? 'Es obligatorio' : intl.formatMessage({id: "form_login_password"})}
                                        />
                                        <br/>
                                        <div style={{float:"right"}}
                                            className='form-control-login-lost-password'>{intl.formatMessage({id: "form_login_lost_password"})}
                                            <br/>
                                                 <br/>
                                        </div>
                                    </section>
                                    <section>
                                        <Fab className={"btn-login-inactive backgrtrans"} aria-label="add" disabled={isSubmitting} style={{float:'left',margin:'5px 0px 0px 0px'}}
                                             type="submit">
                                            {intl.formatMessage({id: "button_continue"})}
                                        </Fab>
                                    </section>
                                </Row>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </>
    )
}
